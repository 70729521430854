import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { ClientForm } from '../../models/ClientForm';
import { TabStrip } from '../shared/tab-strip/TabStrip';
import { FormType } from '../../models/FormTypes';
import { Trans, useTranslation } from 'react-i18next';
import { formStatusKeys } from '../../models/ClientFormStatus';
import { ClientFormUserRole, ClientFormUserRoleKeys } from '../../models/ClientFormUserRoles';
import Accordion from '../shared/accordion/Accordion';
import FormVersionHistory from './FormVersionHistory';
import LanguageUtils from '../../utils/LanguageUtils';
import { ClientModule } from '../../models/ClientModule';
import ModuleService from '../../services/ModuleService';
import DateUtils from '../../utils/DateUtils';
import { ClientFormUser } from '../../models/ClientFormUser';
import { useRecoilValue } from 'recoil';
import { deDuplicate } from '../../utils/ListUtils';
import { PeriodicReviewChecklistItemInstance } from '../../models/Form';
import { currentUserAtom } from '../../recoil/atoms/Auth';
import { clientFormRoute } from '../../utils/NavigationUtils';
import { Link } from 'react-router-dom';
import { FCWithChildren } from '../../types/FCWithChildren';
import Tooltip from '../shared/Tooltip';
import InfoIcon from '../shared/icon/InfoIcon';
import PeriodicReviewRecurrence from '../shared/periodic-review/PeriodicReviewRecurrence';
import { PeriodicReviewUtils } from '../../utils/PeriodicReviewUtils';
import PeriodicChecklistViewer from './PeriodicChecklistViewer';
import { PeriodicReviewStatus } from '../../models/PeriodicReview';
import { FileUtils } from '../../utils/FileUtils';
import { DocumentResponse } from '../../models/Document';
import { AccessTypeKeys } from '../../models/Access';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import StringUtils from '../../utils/StringUtils';
import { Associations } from '../../models/Association';
import AttachmentIcon from '../shared/icon/AttachmentIcon';
import FilePreview from './renderer/FilePreview';

type Props = {
  clientForm: ClientForm;
  clientFormUsers: ClientFormUser[];
  associations: Associations[];
  onChecklistChanged: (checklist: PeriodicReviewChecklistItemInstance[]) => void;
};

const Table: FCWithChildren = (props) => {
  const { children } = props;
  return (
    <table className="border-gray-3 w-full border border-b-0">
      <tbody>{children}</tbody>
    </table>
  );
};

const Row: FCWithChildren = (props) => {
  const { children } = props;
  return <tr className="border-gray-3 border-b">{children}</tr>;
};

const ColPair: FC<{ title?: ReactNode; value?: ReactNode }> = (props) => {
  const { title, value } = props;

  return (
    <>
      <th className={`w-[15%] min-w-[150px] px-3 py-1 font-medium ${title ? 'bg-gray-5' : ''} text-left`}>{title}</th>
      <td className="text-gray-1 w-[45%] px-3 py-1 font-medium">{value}</td>
    </>
  );
};

const WrappedInfo: FC<{ text: string }> = ({ text }) => (
  <Tooltip text={text}>
    {(tooltip) => (
      <span {...tooltip}>
        <InfoIcon className="h-4 w-4" />
      </span>
    )}
  </Tooltip>
);

const Info = (text: string) => <WrappedInfo text={text} />;

const FormInfoTabs: FC<Props> = (props) => {
  const { clientForm, clientFormUsers, onChecklistChanged, associations } = props;
  const [module, setModule] = useState<ClientModule | null>(null);
  const currentUser = useRecoilValue(currentUserAtom);
  const currentClient = useRecoilValue(currentClientAtom);
  const { t } = useTranslation(['form', 'common', 'periodic-review', 'documents']);

  useEffect(() => {
    ModuleService.getModule(clientForm.clientModuleId).then((res) => {
      setModule(res.data);
    });
  }, [clientForm.clientModuleId]);

  const moduleName = useMemo(() => {
    if (!module) {
      return '-';
    }
    return LanguageUtils.getTranslation('name', module.templateModule.translations);
  }, [module]);

  const usersByType = useMemo(() => {
    const result: Partial<Record<(typeof ClientFormUserRole)[keyof typeof ClientFormUserRole], string[]>> = {};

    const users = deDuplicate(clientFormUsers, 'id');

    for (const user of users) {
      if (!result[user.role]) {
        result[user.role] = [];
      }
      result[user.role]?.push(user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.email || '');
    }

    return result;
  }, [clientFormUsers]);

  return (
    <TabStrip borderless>
      <TabStrip.TabHeader id="details" value={null} text={t('form:info-modal.tabs.details')} />
      <TabStrip.TabHeader id="history" value={null} text={t('form:info-modal.tabs.history')} />
      <TabStrip.TabHeader id="dependencies" value={null} text={t('form:info-modal.tabs.dependencies')} />
      {clientForm.type === FormType.Document && <TabStrip.TabHeader id="attachments" value={null} text={t('form:info-modal.tabs.attachments')} />}
      {clientForm.periodicReviewChecklist &&
        (clientForm.periodicReviewStatus === PeriodicReviewStatus.UnderReview ||
          clientForm.periodicReviewStatus === PeriodicReviewStatus.ReviewOverdue) && (
          <TabStrip.TabHeader id="checklist" value={null} text={t('form:info-modal.tabs.checklist')} />
        )}

      <TabStrip.TabContent forId="details">
        <div className="py-2">
          <Accordion title={t('form:info-modal.details.sections.basic-info.title')} active titleClassName="flex-row-reverse !justify-end">
            <Table>
              <Row>
                <ColPair
                  title={t('form:info-modal.details.sections.basic-info.fields.subtitle')}
                  value={clientForm.subtitle || LanguageUtils.getTranslation('title', clientForm.form.translations)}
                />
              </Row>
              <Row>
                <ColPair title={t('form:info-modal.details.sections.basic-info.fields.version')} value={`v${clientForm.majorVersion}`} />
                {clientForm.type === FormType.Document && clientForm.form.isSystem && (
                  <ColPair
                    title={t('form:info-modal.details.sections.basic-info.fields.format')}
                    value={FileUtils.getFileExtensionFromMimeType((clientForm as DocumentResponse).fileFormat)}
                  />
                )}
              </Row>
              <Row>
                <ColPair title={t('form:info-modal.details.sections.basic-info.fields.status')} value={t(formStatusKeys[clientForm.status])} />
                {clientForm.type === FormType.Document && clientForm.form.isSystem && (
                  <ColPair
                    title={t('form:info-modal.details.sections.basic-info.fields.size')}
                    value={FileUtils.formatSize((clientForm as DocumentResponse).fileSize)}
                  />
                )}
              </Row>
              <Row>
                <ColPair
                  title={t(
                    clientForm.type === FormType.Document
                      ? 'form:info-modal.details.sections.basic-info.fields.class'
                      : 'form:info-modal.details.sections.basic-info.fields.module',
                  )}
                  value={moduleName}
                />
                {clientForm.type === FormType.Document && (
                  <ColPair
                    title={t('form:info-modal.details.sections.basic-info.fields.number')}
                    value={StringUtils.addLeadingZeros((clientForm as DocumentResponse).documentNumber)}
                  />
                )}
              </Row>
              <Row>
                <ColPair
                  title={t('form:info-modal.details.sections.basic-info.fields.access')}
                  value={t(AccessTypeKeys[(clientForm as DocumentResponse).accessType], { client: currentClient?.name })}
                />
              </Row>
              <Row>
                {!clientForm.form.isSystem && (
                  <ColPair
                    title={t('form:info-modal.details.sections.basic-info.fields.template')}
                    value={LanguageUtils.getTranslation('title', clientForm.form.translations)}
                  />
                )}
              </Row>
              <Row>
                <ColPair
                  title={t('form:info-modal.details.sections.basic-info.fields.last-modified')}
                  value={DateUtils.formatDate(new Date(clientForm.modifiedUtc))}
                />
              </Row>
            </Table>
          </Accordion>

          <Accordion title={t('form:info-modal.details.sections.members.title')} active titleClassName="flex-row-reverse !justify-end">
            <Table>
              <Row>
                <ColPair title={t(ClientFormUserRoleKeys[ClientFormUserRole.Owner])} value={usersByType[ClientFormUserRole.Owner]?.join(', ')} />

                <ColPair
                  title={t(ClientFormUserRoleKeys[ClientFormUserRole.Validator])}
                  value={usersByType[ClientFormUserRole.Validator]?.join(', ')}
                />
              </Row>

              <Row>
                <ColPair
                  title={t(ClientFormUserRoleKeys[ClientFormUserRole.Contributor])}
                  value={usersByType[ClientFormUserRole.Contributor]?.join(', ')}
                />

                <ColPair
                  title={t(ClientFormUserRoleKeys[ClientFormUserRole.Approver])}
                  value={usersByType[ClientFormUserRole.Approver]?.join(', ')}
                />
              </Row>

              <Row>
                <ColPair title={t(ClientFormUserRoleKeys[ClientFormUserRole.Viewer])} value={usersByType[ClientFormUserRole.Viewer]?.join(', ')} />
              </Row>
            </Table>
          </Accordion>

          {clientForm.periodicReviewConfig && (
            <Accordion title={t('form:info-modal.details.sections.periodic-review.title')} active titleClassName="flex-row-reverse !justify-end">
              <Table>
                <Row>
                  <ColPair
                    title={
                      <Trans
                        t={t}
                        i18nKey="form:info-modal.details.sections.periodic-review.fields.due-date"
                        components={{
                          InfoIcon: Info(t('form:info-modal.details.sections.periodic-review.fields.due-date-help')),
                        }}
                      />
                    }
                    value={
                      clientForm.periodicReviewDueDateUtc
                        ? DateUtils.formatDate(new Date(clientForm.periodicReviewDueDateUtc))
                        : t('form:info-modal.details.sections.periodic-review.fields.due-date-placeholder')
                    }
                  />
                  <ColPair
                    title={t('form:info-modal.details.sections.periodic-review.fields.recurrence')}
                    value={<PeriodicReviewRecurrence source={clientForm} />}
                  />
                </Row>
                <Row>
                  <ColPair
                    title={
                      <Trans
                        t={t}
                        i18nKey="form:info-modal.details.sections.periodic-review.fields.advanced-notice"
                        components={{
                          InfoIcon: Info(t('form:info-modal.details.sections.periodic-review.fields.advanced-notice-help')),
                        }}
                      />
                    }
                    value={PeriodicReviewUtils.getAdvancedNoticeText(clientForm.periodicReviewConfig, t)}
                  />
                  <ColPair
                    title={
                      <Trans
                        t={t}
                        i18nKey="form:info-modal.details.sections.periodic-review.fields.completed-count"
                        components={{
                          InfoIcon: Info(
                            t('form:info-modal.details.sections.periodic-review.fields.completed-count-help', {
                              Count: clientForm.periodicReviewCount,
                            }),
                          ),
                        }}
                      />
                    }
                    value={clientForm.periodicReviewCount}
                  />
                </Row>
                <Row>
                  <ColPair title={t('form:info-modal.details.sections.periodic-review.fields.goal')} value={clientForm.periodicReviewConfig.goal} />
                </Row>
                <Row>
                  <ColPair
                    title={t('form:info-modal.details.sections.periodic-review.fields.repercussions')}
                    value={clientForm.periodicReviewConfig.repercussions}
                  />
                </Row>
              </Table>
            </Accordion>
          )}
        </div>
      </TabStrip.TabContent>

      <TabStrip.TabContent forId="history">
        <div className="p-4">
          <FormVersionHistory clientForm={clientForm} />
        </div>
      </TabStrip.TabContent>

      <TabStrip.TabContent forId="dependencies">
        <div className="relative p-4">
          {associations.map((reference) => {
            return (
              <div data-cy="dependency-group" key={reference.templateId} className="mb-4">
                <div className="text-dpm-16 font-medium text-black" data-cy="dependency-template">
                  {reference.templateTitle}
                </div>
                {reference.associations.map((form) => {
                  let title =
                    !!form.prefix && form.documentNumber !== undefined
                      ? `${StringUtils.makePrefixWithNumber(form.prefix, form.documentNumber, form.templateModuleTranslations)}-`
                      : '';
                  title = `${title}${form.subtitle || LanguageUtils.getTranslation('title', form.translations)}`;

                  return (
                    <div key={form.id} data-cy="dependency">
                      {form.canView && (
                        <Link to={clientFormRoute(form, currentUser)} className="text-dpm-14 my-1 hover:underline" data-cy="dependency-link">
                          {title}
                        </Link>
                      )}
                      {!form.canView && (
                        <div className="text-dpm-14 my-1" data-cy="dependency-readonly">
                          {title}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
          {associations.length === 0 && t('form:info-modal.dependencies.none')}
        </div>
      </TabStrip.TabContent>

      {clientForm.type === FormType.Document && (
        <TabStrip.TabContent forId="attachments">
          <div className="relative p-4">
            {clientForm?.attachments?.map((attachment, i) => {
              const title = `${StringUtils.makePrefixWithNumber(
                (clientForm as DocumentResponse).prefix,
                (clientForm as DocumentResponse).documentNumber,
                (clientForm as DocumentResponse).templateModuleTranslations,
              )}-${StringUtils.addLeadingZeros(i + 1)}-${attachment.fileName}`;
              return (
                <FilePreview
                  key={attachment.fileId}
                  fileInfo={{
                    id: attachment.fileId,
                    type: attachment.fileFormat,
                    name: attachment.fileName,
                    file: null!,
                    mimeType: attachment.fileFormat,
                    size: 0,
                  }}
                >
                  <div className={`hover:bg-background-1 flex cursor-pointer items-center gap-2 py-3 transition-colors ${i === 0 ? '' : 'border-t'}`}>
                    <div className="mx-2 flex-shrink-0">
                      <AttachmentIcon className="h-5 w-5" />
                    </div>
                    <div className="flex-grow truncate">
                      <div>
                        <Tooltip text={title} truncatedTextMode>
                          {(tooltip) => {
                            return (
                              <div {...tooltip} className="truncate font-medium underline">
                                {title}
                              </div>
                            );
                          }}
                        </Tooltip>
                        <div className="text-dpm-12">{FileUtils.getFileExtensionFromMimeType(attachment.fileFormat)}</div>
                      </div>
                    </div>
                  </div>
                </FilePreview>
              );
            })}
            {clientForm?.attachments?.length === 0 && t('form:attachments.empty')}
          </div>
        </TabStrip.TabContent>
      )}

      {clientForm.periodicReviewChecklist &&
        (clientForm.periodicReviewStatus === PeriodicReviewStatus.UnderReview ||
          clientForm.periodicReviewStatus === PeriodicReviewStatus.ReviewOverdue) && (
          <TabStrip.TabContent forId="checklist">
            <div className="px-4">
              <PeriodicChecklistViewer
                clientFormId={clientForm.id}
                checklist={clientForm.periodicReviewChecklist}
                onChecklistChanged={onChecklistChanged}
              />
            </div>
          </TabStrip.TabContent>
        )}
    </TabStrip>
  );
};

export default FormInfoTabs;
